<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置：
          <router-link tag="a" to="/">首页</router-link>
          >
          <router-link
              tag="a"
              to="/bm/list"
          >我的课程
          </router-link
          >
          >

          {{ info.title }}
        </div>
        <!-- 企业定制化培训 -->
        <div class="class-box-1">
          <div class="class-box-1L">
            <div class="class-box-1L-1">{{ info.title }}</div>
            <div class="class-box-1L-2">
              <div style="width: 100%; height: auto; overflow: hidden">
                <div style="width: 50%; height: auto; float: left; overflow: hidden">
                  <div class="class-box-1L-2-1">
                    <img src="@/assets/image/class-1.png" alt=""/>
                    <!-- <span>报名人数：{{ info.totalSign }}/{{ info.maxSign }}</span> -->
                    <span
                    >报名人数：{{ info.totalSign }}/
                      <span v-if="info.maxSign <= 0">无限制人数</span>
                      <span v-else>{{ info.maxSign }}</span>
                    </span>
                  </div>
                  <div class="class-box-1L-2-1 class-box-1L-2-2" v-if="courseType=== '2'">
                    <img src="@/assets/image/class-2.png" alt=""/>
                    <span>报名截止日期：{{ info.signAsOfTime }}</span>
                  </div>
                </div>
                <div v-if="courseType=== '2'" style="width: 50%; height: auto; float: left; overflow: hidden">
                  <div class="class-box-1L-2-1 class-box-1L-2-2">
                    <img src="@/assets/image/class-2.png" alt=""/>
                    <span>培训开始日期：{{ info.courseStartTime }}</span>
                  </div>
                  <div class="class-box-1L-2-1 class-box-1L-2-2">
                    <img src="@/assets/image/class-2.png" alt=""/>
                    <span>培训结束日期：{{ info.courseEndTime }}</span>
                  </div>
                </div>
              </div>
              <div class="class-box-1L-2-1 class-box-1L-2-3">
                <img src="@/assets/image/class-3.png" alt=""/>
                <span>培训类型：{{ wayFormat(info.way) }}</span>
              </div>
              <!-- <div class="class-box-1L-2-4">我要报名</div> -->
              <!-- 取消报名 -->
              <div v-if="info.unitPrice > 0">
                <div
                    class="class-box-1L-2-4"
                    v-if="info.affirmTime == null && info.way == 0"
                    @click="cancelSignup()"
                >
                  取消报名
                </div>
                <!-- 重新报名 -->
                <div
                    class="class-box-1L-2-4"
                    v-if="info.affirmTime == null && info.way == 0 && info.signId == null"
                    @click="setSignup()"
                >
                  重新报名
                </div>
              </div>

              <!-- 去上课 -->
              <div
                  class="class-box-1L-2-4"
                  v-show="info.affirmTime != null && info.way == 0"
                  @click="cqcLogin()"
              >
                去上课
              </div>
              <div
                  class="class-box-1L-2-4 class-box-1L-2-4_new"
                  v-show="info.affirmTime != null && info.way == 0"
                  @click="download_course"
              >
                下载课件
              </div>
            </div>
          </div>
        </div>
        <!-- 当前报名进度 -->
        <div class="class-box-1 sign-pro-1">
          <div class="class-box-1L">
            <div class="class-box-1L-1">当前报名进度</div>
            <div v-if="info.bmTime != null && info.way == 0">
              <!-- 判断是否免费 -->
              <div class="class-box-1L-2 sign-pro-2" v-if="info.unitPrice > 0">
                <el-steps
                    direction="vertical"
                    finish-status="success"
                    :active="dealStatus(info.status)"
                >
                  <el-step title="提交报名信息">
                    <template slot="description">
                      <div class="sign-pro-2box-1">报名时间：{{ info.bmTime }}</div>
                    </template>
                  </el-step>
                  <!-- <div v-if="info.unitPrice > 0"> -->
                  <el-step title="上传转账凭证">
                    <template slot="description">
                      <div class="sign-pro-2box-2">
                        <div class="sign-pro-2box-2a" v-show="info.evaluationTime">
                          上传时间：{{ info.evaluationTime }}
                        </div>
                        <div class="sign-pro-2box-2a">
                          <span class="x-span">*</span>
                          转账成功后请在此上传您的转账凭证照片
                        </div>
                        <div class="sign-pro-2box-2a">上传内容</div>

                        <el-upload
                            class="avatar-uploader"
                            action="/dev-api/common/upload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                        >
                          <img :src="info.evaluation" alt/>
                          <img
                              v-show="info.evaluation == null"
                              class="tx-img"
                              src="@/assets/image/upload.png"
                              alt=""
                          />
                          <div class="tx-img tx-img2" v-show="info.evaluation == null">
                            上传凭证
                          </div>
                        </el-upload>
                        <input
                            type="button"
                            class="pic-btn"
                            @click="submitForm()"
                            value="提交"
                            v-show="info.affirmTime == null"
                        />
                        <div class="sign-pro-2box-2a" v-show="info.status == '4'">
                          已被驳回，请重新上传凭证
                        </div>
                        <div class="sign-pro-2box-2a" v-show="info.status == '4'">
                          驳回原因：{{ info.rejectReason }}
                        </div>
                      </div>
                    </template>
                  </el-step>
                  <el-step title="平台已确认收款">
                    <template slot="description">
                      <div class="sign-pro-2box-2">
                        <div class="sign-pro-2box-2a" v-show="info.affirmTime != null">
                          操作时间：{{ info.affirmTime }}
                        </div>
                        <div class="sign-pro-2box-2a" v-show="info.affirmTime != null">
                          <p>需按照指引上传企业开票信息，<el-button type="text" style="font-size: 15px" @click="handleDownload">查看详情</el-button>
                          </p>
                          <p>增值税专用发票/普通发票，在培训结束后10-15个工作日寄出</p>
                        </div>
                      </div>
                    </template>
                  </el-step>
                  <el-step title="平台发票已寄出">
                    <template slot="description">
                      <div class="sign-pro-2box-2">
                        <div class="sign-pro-2box-2a" v-show="info.finishTime != null">
                          操作时间：{{ info.finishTime }}
                        </div>
                        <div class="sign-pro-2box-2a" v-show="info.waybillNum != null">
                          <span class="kd-s1">发票快递单号：</span
                          ><span class="kd-s1" id="content">{{ info.waybillNum }}</span
                        ><span
                            class="copyTrackingNumber copy-btn"
                            data-clipboard-target="#content"
                            @click="copyTrackingNumber"
                        >复制</span
                        >
                        </div>
                        <div
                            class="sign-pro-2box-2a"
                            v-show="info.courierCompany != null"
                        >
                          快递公司：{{ info.courierCompany }}
                        </div>
                      </div>
                    </template>
                  </el-step>
                </el-steps>

                <!-- <el-button style="margin-top: 12px" @click="next"
                >下一步</el-button
              > -->
              </div>
              <div class="class-box-1L-2 sign-pro-2" v-if="info.unitPrice <= 0">
                <el-steps
                    direction="vertical"
                    finish-status="success"
                    :active="dealStatus(info.status)"
                >
                  <el-step title="提交报名信息">
                    <template slot="description">
                      <div class="sign-pro-2box-1">报名时间：{{ info.bmTime }}</div>
                    </template>
                  </el-step>
                  <el-step title="">
                    <template slot="description">
                      <div class="sign-pro-2box-2">
                        <div class="sign-title" v-show="info.affirmTime == null">
                          平台待确认
                        </div>
                        <div class="sign-title" v-show="info.affirmTime != null">
                          平台已确认
                        </div>
                        <div class="sign-pro-2box-2a" v-show="info.affirmTime != null">
                          操作时间：{{ info.affirmTime }}
                        </div>
                      </div>
                    </template>
                  </el-step>
                </el-steps>

                <!-- <el-button style="margin-top: 12px" @click="next"
                >下一步</el-button
              > -->
              </div>
            </div>
            <div class="class-box-1L-1a" v-else>当前报名课程已取消</div>
          </div>
        </div>
        <!-- 取消提示 -->
        <!-- <div class="class-box-1L-1" v-else>当前报名课程已取消</div> -->
      </div>
      <!-- 取消报名 -->
      <el-dialog
          title=""
          :visible.sync="cancelSignupdialog"
          width="500px"
          :append-to-body="true"
          :before-close="handleClose"
          :close-on-click-modal="false"
          @close="close_page"
      >
        <div class="classDetail-box classDetail-box-new cancelSignu">
          <div class="classDetail-box2 classDetail-box2-new">
            <!-- 当前号码 -->
            <div class="detail-tel">当前号码: {{ phone | phoneFilter }}</div>
            <!-- 提示文案 -->
            <div class="detail-title">
              您好，如需取消报名，为了确保为本人操作，请通过以下方式获取手机短信验证码进行验证，谢谢
            </div>
            <!-- 验证码 -->
            <el-form ref="form" label-width="0px" :model="form" :rules="rules">
              <el-form-item class="yzm-box" prop="code">
                <div class="verififcation">
                  <el-input type="text" v-model="form.code" placeholder="请输入验证码"/>
                  <!-- <input
                    v-show="!epmty"
                    type="button"
                    :disabled="!send"
                    class="yzm"
                    :value="codetext"
                    @click="seedCode"
                    v-bind="teSting"
                  /> -->
                  <input
                      type="button"
                      class="yzm"
                      :value="codetext"
                      :disabled="!send"
                      @click="seedCode"
                  />
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="suc-btn" @click="cancelForm"
                >确认取消报名
                </el-button
                >
              </el-form-item>
            </el-form>
            <!-- close -->
            <img
                class="class-close class-close-new"
                src="@/assets/image/close.png"
                alt=""
            />
            <!-- 填写报名信息 -->
            <div class="class-top">取消报名</div>
          </div>
        </div>
      </el-dialog>
      <!-- 文件下载 -->
      <el-dialog
          title=""
          :visible.sync="dialogVisible3"
          width="500px"
          :append-to-body="true"
          :before-close="handleClose"
          :close-on-click-modal="false"
          @close="close_page"
      >
        <div class="classDetail-box classDetail-box-new">
          <div class="classDetail-box2 classDetail-box2-new">
            <el-checkbox-group
                v-if="download_courseList.length !== 0"
                v-model="download_courseIds"
                class="download-list"
                v-loading="download_load"
                @change="handleCheckedCitiesChange"
            >
              <li v-for="(item, index) in download_courseList" :key="index">
                <el-checkbox
                    :label="item.name + '.' + item.suffix"
                    :value="item.id"
                    name="type"
                ></el-checkbox>
                <!-- <span>.doc</span> -->
              </li>
            </el-checkbox-group>
            <div class="msg" v-else>暂时没有资料下载</div>
            <div class="select-amount">
              已选
              <span class="red">{{ download_courseIds.length }}</span> 个课件
            </div>
            <!-- close -->
            <img
                class="class-close class-close-new"
                src="@/assets/image/close.png"
                alt=""
            />
            <!-- 填写报名信息 -->
            <div class="class-top">资料下载</div>
            <div style="height: 45px; line-height: 45px">
              <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
              >全选
              </el-checkbox
              >
              <el-button
                  class="class-btn2 class-btn3 class-btn4"
                  :type="disabled ? 'disabled' : 'primary'"
                  @click="submitDownload"
                  :disabled="disabled"
              >下载
              </el-button
              >
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getCourseJoinUser, getDownloadList} from "@/api/bm/course";
import ClipboardJS from "clipboard";
import {uploadSumit} from "@/api/bm/upload";
import {cancelSign, cancelSignCode} from "@/api/bm/myinvitation";
import {isWeiXin} from "@/utils/baike";
import {domain} from "../../utils/request";
import {download} from '@/common/utils/tools.js'

export default {
  data() {
    return {
      codetext: "获取验证码",
      send: true,
      //验证码样式
      sendcode: true,
      phone: "18820282028",
      codein: false,
      funikm: true,
      epmty: false,
      code: "",
      queryFormsignId: "",
      form: {
        code: undefined,
      },
      rules: {
        code: [{required: true, message: "请输入验证码", trigger: "blur"}],
      },
      disabled: false,
      checkAll: false,
      cancelSignupdialog: false,
      isIndeterminate: true,
      dialogVisible3: false,
      // 下载课程资料列表
      download_courseList: [],
      download_courseIds: [],

      activeName: "first",
      active: 0,
      way: "",
      isDisable: false,
      // 上传参数
      upload: {
        // 是否禁用上传
        isUploading: false,
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/common/upload",
        // 上传的文件列表
        fileList: [],
      },
      formMess: {
        // id: localStorage.getItem("signId2"),
        id: localStorage.getItem("signId"),
        // id: 0,
        imageUrl: null, //上传图片路径imageUrl
      },
      dialogImageUrl: "",
      dialogVisible: false,
      info: {},
      wayOptions: [],
      statusOptions: [
        {
          dictValue: 0,
          dictLabel: "待上传凭证",
        },
        {
          dictValue: 1,
          dictLabel: "待寄出发票",
        },
        {
          dictValue: 2,
          dictLabel: "待确认收款",
        },
      ],
      courseType: null
    };
  },
  methods: {
    async handleDownload() {
      const {msg} = await this.getConfigKey('invoiceGuide')
      download(msg,'关于开具增值税发票的操作指引')
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
      this.dialogVisible2 = false;
      // this.$confirm("确认关闭？")
      //   .then(() => {
      //     done();
      //   })
      //   .catch(() => {});
    },

    // 点击 下载课件弹出层
    download_course() {
      this.dialogVisible3 = true;
      this.download_load = true;
      let name_id = {};
      getDownloadList({courseId: this.$route.query.id, isDel: "N"}).then((res) => {
        this.download_load = false;
        res.rows.map((item) => {
          name_id[item.name + "." + item.suffix] = item.id;
        });
        this.name_id_obj = name_id;
        console.log("文件名andID号对象：", this.name_id_obj);
        console.log("获取课程下载列表：", res);
        this.download_courseList = res.rows;
      });
    },
    close_page() {
      this.download_courseIds = [];
    },
    handleCheckedCitiesChange(value) {
      // console.log(value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.download_courseList.length;
      this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.download_courseList.length;
    },
    handleCheckAllChange(val) {
      // console.log(val);
      this.download_courseIds = val
          ? this.download_courseList.map((res) => {
            return res.name + "." + res.suffix;
          })
          : [];
      this.isIndeterminate = false;
    },
    // 点击下载课件
    submitDownload() {
      console.log(this.download_courseIds);
      let ids = [],
          _this = this;
      this.download_courseIds.map((item) => {
        ids.push(this.name_id_obj[item]);
      });
      if (ids.length !== 0) {
        this.$confirm("是否确认下载所选课程相关的课件?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(function () {
              console.log(ids.toString());
              window.open(
                  process.env.VUE_APP_BASE_API + "/bm/material/download/" + ids.toString(),
                  "_self"
              );
              _this.dialogVisible3 = false;
              _this.download_courseIds = [];
            })
            .catch(function () {
            });
      } else {
        this.$message({
          message: "请选择下载课件",
          type: "warning",
        });
      }
    },

    //去上课
    cqcLogin() {
      if (isWeiXin()) {
        this.$alert('微信浏览器尚不支持直播课程学习，请使用其他浏览器打开网站登录学习！', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
        return null;
      }
      const user = this.$store.getters.user;
      window.open(
          // "http://113.108.126.152:3098/cqc-baike-web/platformCourseDetail?id="+this.$route.query.id,
          // "http://cqc.pinxueyun.com/cqc-baike-web/platformCourseDetail?id="+this.$route.query.id,
          domain + "platformCourseDetail?id=" + this.$route.query.id,
          "_blank"
      );
      // window.open(
      //   "http://cqc.baikegz.com/login?au=1&us=" +
      //     user.username +
      //     "&pw=" +
      //     user.password +
      //     "&goto=" +
      //     "http://cqc.baikegz.com/my/courses/learning",
      //   "_blank"
      // );
    },
    //取消报名
    cancelSignup() {
      this.cancelSignupdialog = true;
      // this.$router.go(0);
    },
    cancelSignup1() {
      // this.cancelSignupdialog = true;
      this.$router.go(0);
    },
    // 重新报名
    setSignup() {
      console.log("重新报名");
      this.$router.push({
        name: "signup",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    //拷贝快递单号
    copyTrackingNumber() {
      this.$message("单号复制成功");
      // 括号中的内容：对应复制按钮的class
      let clipboard = new ClipboardJS(".copyTrackingNumber");
      clipboard.on("success", function (e) {
        console.log("复制成功");
        //  this.$message('这是一条消息提示')
        //  this.$message('这是一条消息提示');
        // Toast.success("快递单号复制成功");
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        console.log("复制失败，请重试");
        // Toast.success("复制失败，请重试");
        console.log(e);
      });
    },
    //
    //
    getInfo() {
      let id = this.$route.query.id;
      getCourseJoinUser(id).then((res) => {
        this.info = res.data;
        console.log("我的报名888888");
        localStorage.setItem("signId", res.data.signId);
        this.formMess.id = res.data.signId;
        this.queryFormsignId = res.data.signId;
        this.phone = res.data.phone;
        this.courseType = res.data.courseType
      });
    },
    wayFormat(k) {
      return this.selectDictLabel(this.wayOptions, k);
    },
    statusFormat(k) {
      return this.selectDictLabel(this.statusOptions, k);
    },
    dealStatus(status) {
      return [0, 2, 1, 3][status];
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 进度条
    next() {
      if (this.active++ > 3) this.active = 0;
    },
    // 上传图片
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    // 上传图片
    handleAvatarSuccess(res, file) {
      //

      console.log("上传图片");
      console.log(res);
      console.log(res.fileName);
      console.log(file);
      this.info.evaluation = res.fileName;
      console.log(this.info.evaluation);
      this.formMess.imageUrl = res.fileName;
      console.log(this.formMess.imageUrl);
    },
    submitForm() {
      if (!this.formMess.imageUrl) {
        this.$message.error("请选择您需要上传的图片");
        return null;
      }
      /* formData格式提交： */
      let formData = new FormData();
      for (var key in this.formMess) {
        formData.append(key, this.formMess[key]);
      }
      console.log(666, "formMess");
      console.log(this.formMess);
      uploadSumit(this.formMess).then((res) => {
        console.log("上传成功888");
        console.log(res);
        if (res.code == "200") {
          this.$message({
            showClose: true,
            message: "上传成功~",
            type: "success",
          });
          this.isDisable = true;
          setTimeout(() => {
            this.isDisable = false;
            this.$router.push({path: "/bm/list"});
          }, 500);
        }
      });
    },
    eCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadingCertificate() {
      //if()
    },
    // 取消报名发送验证码
    seedCode() {
      let that = this;
      //时间
      let countdown = 60;
      codetime();

      function codetime() {
        if (countdown == 0) {
          that.codetext = "获取验证码";
          countdown = 60;
          that.send = true;
          that.codein = false;
          that.sendcode = true;
          return;
        } else {
          countdown--;
          that.codetext = "" + countdown + "秒后重发";
          that.send = false;
          that.codein = true;
          that.sendcode = false;
        }
        setTimeout(function () {
          codetime();
        }, 1000);
      }

      //发送验证码
      cancelSignCode(this.queryFormsignId).then((res) => {
        console.log("发送验证码成功");
        console.log(res);
      });
    },
    // 取消报名
    cancelForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log("验证成功");
          //发送验证码
          cancelSign(this.queryFormsignId, this.form.code).then((res) => {
            console.log("取消报名成功");
            console.log(res);
            this.$message({
              message: "取消报名成功!",
              type: "success",
            });
            this.cancelSignupdialog = false;
            this.$router.go(0);
          });
        }
      });
    },
  },
  //监听
  computed: {
    //验证手机号码是否正确
    // teSting() {
    //   if (!/^1(3|4|5|7|8|9)\d{9}$/.test(this.form.userName)) {
    //     console.log("手机号码不正确");
    //     this.send = false;
    //     this.epmty = false;
    //   } else {
    //     console.log("手机号码正确");
    //     this.send = true;
    //     this.epmty = false;
    //     this.queryForm.phone = this.form.userName;
    //   }
    // },
  },
  filters: {
    phoneFilter(phone) {
      console.log("打印手机号", phone)
      if (phone != null) {
        let reg = /^(.{3}).*(.{4})$/;
        return phone.replace(reg, "$1****$2");
      }
      // let reg = /^(.{3}).*(.{4})$/;
      // return phone.replace(reg, "$1****$2");
    },
  },

  created() {
    this.getInfo();
    this.getDicts("bm_course_way").then((res) => {
      this.wayOptions = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 20px;

  .el-dialog__headerbtn {
    position: absolute;
    top: -4px;
    right: -2%;
    padding: 0;
    background: 0 0;
    border: 1px solid teal;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    width: 10%;
    height: 50px;
    opacity: 0;
    z-index: 99999;
  }

  .verififcation {
    margin-top: 68px;
  }

  .suc-btn {
    width: 405px;
    height: 52px;
    /* line-height: 52px; */
    text-align: center;
    background: #3d7eff;
    border-radius: 6px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 40px;
    cursor: pointer;
    margin-left: -3px;
  }

  .el-input__inner {
    /* width: 40%; */
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: inherit;
    border: none;
    border-bottom: 1px solid #ddd;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 70%;
  }

  .yzm-box {
    position: relative;

    .yzm {
      position: absolute;
      top: 63px;
      right: 0px;
      width: 119px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #1d70ea;
      border-radius: 6px;
      color: #fff;
      font-size: 16px;
      border: 0;
      outline: 0;
      cursor: pointer;
    }
  }
}

// ::v-deep .el-dialog {
//   border-radius: 3%;
//   .el-dialog__body {
//     position: relative;
//   }
//   .el-step__title.is-success {
//     font-size: 16px;
//     font-family: Microsoft YaHei;
//     font-weight: 700;
//     color: #27333b;
//   }
//   .el-step__head.is-success {
//     color: #2979ea;
//     border-color: #2979ea;
//   }
//   .el-dialog__headerbtn .el-dialog__close {
//     color: #909399;
//     width: 35px;
//     height: 35px;
//     border: 1px solid tan;
//     position: absolute;
//     top: -29px;
//     right: -37px;
//     opacity: 0;
//     z-index: 99999;
//   }
//   .class-sucessbox {
//     width: 90%;
//     height: 600px;
//     // border: 1px solid tan;
//     margin: 0 auto;
//     // position: relative;
//     .info-title {
//       width: 85%;
//       height: auto;
//       background: #f2f2f2;
//       border-radius: 6px;
//       margin: 0 auto;
//       display: flex;
//       flex-direction: row;
//       .info-title_L {
//         width: 20%;
//         height: auto;
//         font-size: 18px;
//         font-family: Microsoft YaHei;
//         font-weight: bold;
//         color: #27333b;
//         text-align: center;
//         padding: 27px 0px;
//       }
//       .info-title_R {
//         width: 80%;
//         height: auto;
//         padding: 22px 0px;
//         font-size: 15px;
//         font-family: Microsoft YaHei;
//         font-weight: 400;
//         line-height: 25px;
//         color: #27333b;
//       }
//     }
//     .info-progress {
//       width: 72%;
//       margin: 45px auto;
//       .sign-pro-2box-1 {
//         font-size: 15px;
//         font-family: Microsoft YaHei;
//         font-weight: 400;
//         color: #a3a4a4;
//         padding-top: 5px;
//         padding-bottom: 30px;
//       }
//       .sign-pro-2box-2a {
//         font-size: 15px;
//         font-family: Microsoft YaHei;
//         font-weight: 400;
//         line-height: 25px;
//         color: #a3a4a4;
//       }
//       .sign-pro-2box-2b {
//         padding-bottom: 30px;
//       }
//     }

//     img {
//       position: absolute;
//       right: -1.5%;
//       top: -43px;
//     }
//     .class-top {
//       width: 30%;
//       height: 65px;
//       line-height: 65px;
//       text-align: center;
//       background: #3d7eff;
//       -webkit-box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
//       box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
//       border-radius: 19px;
//       position: absolute;
//       top: -65px;
//       left: 34%;
//       font-size: 18px;
//       font-family: Microsoft YaHei;
//       font-weight: 400;
//       color: #ffffff;
//     }
//     .suc-btn {
//       width: 341px;
//       height: 52px;
//       line-height: 52px;
//       text-align: center;
//       background: #3d7eff;
//       border-radius: 6px;
//       font-size: 18px;
//       font-family: Microsoft YaHei;
//       font-weight: bold;
//       color: #ffffff;
//       margin: 0 auto;
//       margin-top: 25px;
//       cursor: pointer;
//     }
//   }
// }
.classDetail-box {
  width: 100%;
  height: 550px;
  // border: 1px solid teal;

  .classDetail-box2 {
    width: 90%;
    height: 550px;
    // border: 1px solid tan;
    margin: 0 auto;

    .classDetail-box-1 {
      width: 100%;
      height: 215px;
      // border: 1px solid teal;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .classDetail-box-1L {
        width: 49%;
        height: 235px;

        ::v-deep .el-form-item__label {
          text-align: left;
          vertical-align: middle;
          float: left;
          font-weight: bold;
          color: #393939;
          line-height: 40px;
          padding: 0 6px 0 0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }

        ::v-deep .el-input__inner {
          -webkit-appearance: none;
          background-color: #fff;
          background-image: none;
          border-radius: 4px;
          border: none;
          border-bottom: 1px solid #e5e5e5;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          color: #606266;
          display: inline-block;
          font-size: inherit;
          height: 40px;
          line-height: 40px;
          outline: 0;
          padding: 0 0px;
          -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          width: 80%;
        }

        border-right: 1px solid rgba(64, 64, 64, 0.1);

        .classDetail-inp-1 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 51px;

          .my-R-1L {
            width: 25%;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #393939;
          }

          ::v-deep .el-input__inner {
            -webkit-appearance: none;
            background-color: #fff;
            background-image: none;
            border-radius: inherit;
            border: none;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: 0;
            padding: 0 15px;
            -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 83%;
            border-bottom: 1px solid #e5e5e5;
          }
        }
      }

      .classDetail-box-1R {
        width: 49%;
        height: 215px;
        margin-left: 6%;
        border-right: none;
      }
    }

    .classDetail-box-2 {
      .tjInput {
        .el-input__inner {
          padding-left: 45px;
        }

        // padding-left: 50px;
      }

      margin-top: 39px;

      ::v-deep .el-form-item__label {
        text-align: right;
        vertical-align: middle;
        float: left;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: left;
      }
    }

    .class-top {
      width: 30%;
      height: 65px;
      line-height: 65px;
      text-align: center;
      background: #3d7eff;
      -webkit-box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
      border-radius: 19px;
      position: absolute;
      top: -34px;
      left: 35%;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .class-btn {
      width: 341px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      background: #3d7eff;
      border-radius: 6px;

      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // margin: 0 auto;
      // margin-top: 2%;
      position: absolute;
      left: 33%;
      bottom: 45px;
      cursor: pointer;
    }

    .class-btn2 {
      width: 341px;
      height: 52px;
      // line-height: 52px;
      text-align: center;
      background: #3d7eff;
      border-radius: 6px;

      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // margin: 0 auto;
      // margin-top: 2%;
      position: absolute;
      left: 33%;
      bottom: 45px;
      cursor: pointer;
    }

    .class-btn3 {
      position: absolute;
      left: 23%;
      bottom: 45px;
    }

    .class-close {
      position: absolute;
      right: -0.5%;
      top: -5px;
    }

    .class-close-new {
      position: absolute;
      right: -1.3%;
      top: -9px;
    }
  }
}

.classDetail-box-new {
  width: 100%;
  height: 360px;
  // border: 1px solid red;
  .classDetail-box2-new {
    width: 400px;
    height: 250px;
    // border-bottom: 1px solid #eee;
    // border-left: 1px solid #eee;
    .detail-tel {
      text-align: center;
      line-height: 40px;
      color: #000;
      font-size: 14px;
    }

    .detail-title {
      line-height: 25px;
    }

    .msg {
      width: 100%;
      height: 250px;
      font-size: 20px;
      color: #606266;
    }

    .download-list {
      width: 100%;
      height: 250px;
      overflow-x: hidden;
      // overflow-y: scroll;
      li {
        height: 40px;

        .el-checkbox {
          height: 40px;
          line-height: 40px;
          width: 400px;
          white-space: nowrap;
          // overflow: hidden;
          display: inherit;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;

          .el-checkbox__label {
            display: inline-block;
            padding-left: 10px;
            position: relative;
            top: -1px;
            height: 15px;
            line-height: 15px;
            font-size: 14px;
            width: 86%;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .el-checkbox:hover {
          color: #3d7eff;
        }
      }
    }

    .download-list::-webkit-scrollbar {
      width: 10px;
      height: 5px;
      // background: red;
    }

    .download-list::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #3d7eff;
    }

    .download-list::-webkit-scrollbar-track {
      -webkit-box-shadow: inset #eee;
      border-radius: 100px;
      background: rgba(0, 0, 0, 0.1);
    }

    .select-amount {
      // border: 1px solid yellow;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      color: #27333b;

      .red {
        color: red;
      }
    }

    .class-btn4 {
      width: 275px;
      height: 45px;
      font-size: 16px;
      position: absolute;
      left: 24%;
      bottom: 45px;
    }
  }

  .course_list {
    display: flex;
    align-items: center;
  }
}

.cancelSignu {
  // height: auto;
  height: 320px;
}

.container {
  height: auto;
  background: #f8f8f8;
  padding-top: 26px;
  padding-bottom: 138px;
  display: flex;

  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;

    .class-nav {
      margin-bottom: 37px;
    }

    .class-box-1 {
      height: 236px;
      // border: 1px solid teal;
      .class-box-1L {
        width: 1300px;
        height: auto;
        background: #ffffff;
        float: left;
        position: relative;
        // padding-bottom: 70px;
        // position: relative;
        .class-box-1L-1 {
          width: 1300px;
          height: 57px;
          line-height: 57px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 4px;
        }

        .class-box-1L-1a {
          width: 1300px;
          height: 300px;
          line-height: 300px;
          text-align: center;
          font-size: 20px;
        }

        .class-box-1L-2 {
          width: 1300px;
          height: 175px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          position: relative;

          .class-box-1L-2-1 {
            padding-top: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              padding-right: 18px;
            }
          }

          .class-box-1L-2-4 {
            width: 150px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(
                    -54deg,
                    rgba(29, 112, 234, 0.89),
                    rgba(72, 140, 240, 0.89)
            );
            border-radius: 4px;
            position: absolute;
            top: 118px;
            right: 280px;
            cursor: pointer;
          }

          .class-box-1L-2-4_new {
            position: absolute;
            right: 440px;
          }
        }
      }

      .class-box-1R {
        width: 262px;
        height: 236px;
        background: #ffffff;
        float: right;

        .class-box-1R-1 {
          width: 262px;
          height: 57px;
          line-height: 57px;
          padding-left: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
        }

        .class-box-1R-2 {
          img {
            width: 80px;
            height: 80px;
            background: #ffffff;
            border-radius: 50%;
            margin-left: 91px;
            margin-top: 24px;
          }

          .class-box-1R-2b {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 21px;

            .class-bs-1 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            }

            .class-bs-2 {
              width: 1px;
              height: 20px;
              background: #c0c0c0;
              margin: 0 18px;
              display: inline-block;
            }

            .class-bs-3 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }
        }
      }
    }

    .sign-pro-1 {
      margin-top: 27px;

      .sign-pro-2 {
        height: auto !important;
        padding-top: 25px;

        .sign-pro-2box-1 {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a3a4a4;
          padding-top: 16px;
          padding-bottom: 80px;
        }

        ::v-deep .el-upload--picture-card {
          background-color: #fbfdff;
          border: 1px solid #ccc;
          border-radius: 6px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 260px;
          height: 260px;
          line-height: 146px;
          vertical-align: top;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        ::v-deep .el-upload--picture-card i {
          font-size: 28px;
          color: #8c939d;
          margin-top: 42%;
        }

        .sign-pro-2box-2 {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a3a4a4;
          //   padding-top: 16px;
          padding-bottom: 80px;

          ::v-deep .el-upload {
            display: inline-block;
            text-align: center;
            cursor: pointer;
            outline: 0;
            position: relative;

            .tx-img {
              position: absolute;
              top: 81px;
              left: 111px;
              width: 33px;
              height: 33px;
            }

            .tx-img2 {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7e7e7e;
              width: 100px;
              top: 137px;
              left: 79px;
            }
          }

          .pic-btn {
            width: 260px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: linear-gradient(
                    -54deg,
                    rgba(29, 112, 234, 0.89),
                    rgba(72, 140, 240, 0.89)
            );
            border-radius: 4px;

            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
            margin-top: 20px;
            border: none;
            outline: none;
          }

          .sign-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 700;
            color: #27333b;
          }

          .sign-pro-2box-2a {
            padding-top: 16px;

            .x-span {
              color: rgba(242, 84, 72, 1);
            }

            .kd-s1 {
              color: #a3a4a4;
            }

            .copy-btn {
              font-size: 13px;
              color: #ffffff;
              padding: 0.4rem 2vw;
              background: linear-gradient(
                      -54deg,
                      rgba(29, 112, 234, 0.89),
                      rgba(72, 140, 240, 0.89)
              );
              margin-left: 1vw;
              border-radius: 4px;
              cursor: pointer;
            }
          }

          img {
            display: inline-block;
            width: 260px;
            height: 195px;

            background: #f2f2f2;
            margin-top: 20px;
          }
        }

        ::v-deep .el-step__title.is-success {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 700;
          color: #27333b;
        }

        ::v-deep .el-step__head.is-success {
          color: #2979ea;
          border-color: #2979ea;
        }
      }

      .class-btn {
        width: 260px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: linear-gradient(
                -54deg,
                rgba(29, 112, 234, 0.89),
                rgba(72, 140, 240, 0.89)
        );
        border-radius: 4px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        margin-top: 20px;
        border: none;
        outline: none;
        position: absolute;
        left: 142px;
        bottom: 22px;
      }

      .class-btn1 {
        position: absolute;
        left: 410px;
        bottom: 22px;
      }
    }
  }
}
</style>
